import { GoogleOAuthProvider } from "@react-oauth/google";
import { GetServerSideProps } from "next";
import Head from "next/head";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect } from "react";

const AuthLayout = dynamic(() => import("../../../app/layouts/Auth"));
const SignIn = dynamic(() => import("../../../app/pages/b/signin/SignIn"));

const Index = (props: any) => {
  const router = useRouter();
  useEffect(() => {
    localStorage.removeItem("b_recovery");
    localStorage.removeItem("b_confirm");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <AuthLayout bg="bg-blur" src="newbid" bgColor="primary" type="sign_in">
      <Head>
        <title>Sign In | Storage Unit Auction List</title>
        <meta
          name="description"
          content="Sign in to the Storage Unit Auction List website to find auctions, manage your account, build your watch list, and add new subscriptions."
        />
      </Head>
      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GAPI_CLIENT_ID}>
        <SignIn />
      </GoogleOAuthProvider>
    </AuthLayout>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  return { props: { data: {} } };
};

export default Index;
